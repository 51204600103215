@import './../../../../scss/theme-bootstrap';

///
/// @file components/prouducts/_product_rating.scss
///
/// \brief Styles for product rating using stars as font icon
///

.product-brief {
  .product-rating {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 12px;
    .pr-snippet-stars {
      align-items: center;
      display: flex;
    }
    .review-count {
      @include body-x-small;
    }
  }
}
